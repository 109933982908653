import React from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";

import { Card, CardBody, CardBodyText, CardHeadline } from "components/cards";
import PageTitle from "components/PageTitle";
import { accommodation } from "config";
import { basics } from "config";

const Accommodation = () => {
  const pricePerHead = (variant) => {
    return `${Math.round(variant.price / variant.bedCount)} Kč`;
  };

  const priceClassClass = (priceClass) => {
    return classNames("text-white font-bold px-2 py-2 text-xs ml-auto", {
      "bg-blue-300 bg-opacity-75": priceClass === "$",
      "bg-blue-300 bg-opacity-85": priceClass === "$$",
      "bg-blue-300": priceClass === "$$$",
    });
  };

  const perex =
    "Chcete si CF 2022 užít naplno bez pendlování z domova do Pardubic? Zařídili jsme pro vás ubytování v šesti hotelech kousek od místa konání.";

  return (
    <article className="space-y-8 w-full">
      <PageTitle>Ubytování</PageTitle>

      <Helmet>
        <title>Ubytování | {basics.appTitle}</title>
        <meta name="description" content={perex} />
        <meta property="og:title" content="Ubytování" />
        <meta property="og:description" content={perex} />
      </Helmet>

      <p className="leading-normal">{perex}</p>

      <div className="alert alert--light flex-col content-block">
        <h3>Jak rezervovat ubytování?</h3>
        <p>
          Nepoužívejte rezervační systémy hotelů. Využijte kapacitu zamluvenou
          jen pro nás. Stačí napsat vybranému hotelu rezervaci emailem s heslem{" "}
          <strong>PIRÁTI</strong>.
        </p>
      </div>

      <div className="grid lg:grid-cols-2 gap-4 md:gap-8 container-padding--zero md:container-padding--auto">
        {accommodation.map((place) => (
          <Card key={place.title} className="relative">
            <CardBody>
              <div className="flex items-center content-center -mx-4 md:-mx-8 pl-4 md:pl-8 pr-0 mb-2">
                <CardHeadline>{place.title}</CardHeadline>
                <span className={priceClassClass(place.priceClass)}>
                  {place.priceClass}
                </span>
              </div>
              <CardBodyText className="space-y-2 md:space-y-4">
                <ul className="leading-loose">
                  <li>
                    <i className="ico--link mr-2" />
                    <a
                      href={place.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong>{place.link}</strong>
                    </a>
                  </li>
                  <li>
                    <i className="ico--envelope mr-2" />
                    <a href={`mailto:${place.email}`}>
                      <strong>{place.email}</strong>
                    </a>
                    , heslo <strong>PIRÁTI</strong>
                  </li>
                  <li>
                    <i className="ico--compass mr-2" />
                    <strong>{place.distance} m</strong>
                  </li>
                  <li>
                    <i className="ico--wheelchair mr-2" />
                    <strong
                      data-tip={place.accessibility.help}
                      className="cf-dashed"
                    >
                      {place.accessibility.status ? "Ano" : "Ne"}
                    </strong>
                  </li>
                </ul>
                {place.desc && <p>{place.desc}</p>}
                <table className="table table--condensed table--light table--unxpadded w-full">
                  <thead>
                    <tr className="text-left">
                      <th>Typ pokoje</th>
                      <th>Cena za osobu</th>
                    </tr>
                  </thead>
                  <tbody>
                    {place.variants.map((v, idx) => (
                      <tr key={idx}>
                        <td className="py-2">{v.bedCount}-lůžkový</td>
                        <td className="py-2">{pricePerHead(v)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBodyText>
            </CardBody>
          </Card>
        ))}
      </div>
    </article>
  );
};

export default Accommodation;
