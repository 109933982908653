import React from "react";
import classNames from "classnames";

const CardActions = ({ children, right, className }) => {
  const cls = classNames(
    "card-actions",
    { "card-actions--right": !!right },
    className
  );
  return <div className={cls}>{children}</div>;
};

export default CardActions;
