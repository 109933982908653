import React from "react";
import { Helmet } from "react-helmet";

import Badge from "components/Badge";
import PageTitle from "components/PageTitle";
import { basics, organisers } from "config";

const Team = () => {
  const isLastItem = (idx) => organisers.length - 1 === idx;

  return (
    <article className="space-y-8 w-full">
      <Helmet>
        <title>Organizátoři | {basics.appTitle}</title>
        <meta property="og:title" content="Organizátoři" />
      </Helmet>

      <PageTitle>Organizátoři</PageTitle>

      {organisers.map((team, idx) => (
        <React.Fragment key={team.title}>
          <section key={team.title}>
            <h2 className="head-heavy-sm mb-4">{team.title}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 row-gap-8 col-gap-8">
              {team.members.map((member) => (
                <Badge
                  name={member.name}
                  position={member.position}
                  avatarUrl={member.avatarUrl}
                  phone={member.phone}
                  email={member.email}
                  key={member.name}
                />
              ))}
            </div>
          </section>
          {!isLastItem(idx) && <hr className="hr--big" />}
        </React.Fragment>
      ))}
    </article>
  );
};

export default Team;
