export const basics = {
  appTitle: process.env.REACT_APP_CF_TITLE || "CF 2022",
  appDesc:
    process.env.REACT_APP_CF_DESC ||
    "Oficiální stránka letošního ročníku zasedání Celostátního fóra České pirátské strany, 8. 1. 2022.",
};

export const api = {
  baseUrl: process.env.REACT_APP_API_BASE_URL || "https://cf2022.pirati.cz/api",
};

export const eventHall = {
  title: "IDEON",
  desc: "Zde bude probíhat jednání celostátního fóra.",
  coords: { lat: 50.03470585017971, lon: 15.781673696482517 },
  type: "event",
  group: "basics",
};

export const railwayStation = {
  title: "Hlavní nádrazí",
  desc: "Pro řadu lidí nejsnazší způsob dopravy do Pardubic.",
  coords: { lat: 50.03250368876455, lon: 15.756306756074403 },
  type: "train",
  group: "transportation",
};

export const parkingKarlovina = {
  title: "Parkovací dům Karlovina",
  desc: "Zde je též možné parkovat.",
  coords: { lat: 50.03662677685316, lon: 15.7788872526747 },
  type: "location",
  group: "transportation",
};

export const parkingIdeon = {
  title: "Parkoviště před IDEONem",
  desc: "Parkoviště je vyhrazeno pro účastníky CF.",
  coords: { lat: 50.03453700303455, lon: 15.781397800200832 },
  type: "location",
  group: "transportation",
};

export const map = {
  center: { lat: 50.03470585017971, lon: 15.781673696482517, zoom: 15 },
  markers: [eventHall, railwayStation, parkingIdeon, parkingKarlovina],
};

export const nav = [
  //  {
  //    link: "/registrace",
  //    title: "Registrace",
  //  },
  {
    link: "/ubytovani",
    title: "Ubytování",
  },
  //  {
  //    title: "Stravování",
  //  },
  {
    link: "/doprava",
    title: "Doprava",
  },
  {
    link: "/program",
    title: "Program",
  },
  {
    title: "Party",
    link: "/party",
  },
  {
    link: "/mapa",
    title: "Mapa",
  },
  {
    link: "/organizatori",
    title: "Organizátoři",
  },
  {
    link: "/volba-predsednictva",
    title: "Volba předsednictva",
  },
  //  {
  //    link: "/pro-dobrovolniky",
  //    title: "Pro dobrovolníky",
  //  },
];

export const organisers = [
  {
    title: "Organizační tým",
    members: [
      {
        name: "Andrea Linhartová",
        position: "Hlavní organizátorka",
        email: "andrea.linhartova@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/andrea-linhartova-2020.jpg",
      },
      {
        name: "Andrej Ramašeuski",
        position: "Registrace, technické zajištění",
        phone: "+420605234197",
        email: "andrej.ramaseuski@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/andrej-ramaseuski-2020.jpg",
      },
      {
        name: "Jan Hrubeš",
        position: "Doprovodný program",
        email: "jan.hrubes@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/jan-hrubes.jpg",
      },
      {
        name: "Karsten Sachs",
        position: "Finance",
        phone: "+420739261443",
        email: "karsten.sachs@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/karsten-sachs.jpg",
      },
      {
        name: "Lucie Procházková",
        position: "Organizátorka",
        email: "lucie.prochazkova@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/lucie.prochazkova-2020.jpg",
      },
      {
        name: "Filip Vařecha",
        position: "Webmaster",
        email: "filip.varecha@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/filip-varecha-2020.jpg",
      },
      {
        name: "David Marhold",
        position: "Krajský koordinátor",
        email: "david.marhold@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/david-marhold-2020.jpg",
      },
      {
        name: "Ivana Mkrvičková",
        position: "Krajská koordinátorka",
        email: "ivana.mrkvickova@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/ivana-mrkvickova.jpg",
      },
    ],
  },
  {
    title: "Předsedající",
    members: [
      {
        name: "Vojtěch Pikal",
        position: "Předsedající",
        phone: "+420778702714",
        email: "vojtech.pikal@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/vojtech-pikal.jpg",
      },
      {
        name: "Georgia Hejduková",
        position: "Předsedající",
        phone: "+420775864103",
        email: "georgia.hejdukova@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/georgia.hejdukova.jpg",
      },
      {
        name: "Lukáš Blažej",
        position: "Předsedající",
        email: "lukas.blazej@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/lukas-blazej.jpg",
      },
      {
        name: "Petr Springinsfeld",
        position: "Předsedající",
        email: "petr.springinsfeld@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/petr-springinsfeld.jpg",
      },
      {
        name: "Jan Novák",
        position: "Předsedající",
        email: "jan.novak@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/jan-novak.jpg",
      },
      {
        name: "Michaela Nepejchalová",
        position: "Zapisovatelka",
        email: "cat.misa@seznam.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/michaela-nepejchalova.jpg",
      },
      {
        name: "Zdeněk Žák",
        position: "Časomíra",
        email: "zdenek.zak@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/zdenek-zak.jpg",
      },
    ],
  },
  {
    title: "Obsluha Heliosu",
    members: [
      {
        name: "Jan Hora",
        position: "Trustee",
        phone: "+420775608734",
        email: "honza.hora@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/jan-hora.jpg",
      },
      {
        name: "Ladislav Dvořák",
        position: "Trustee",
        phone: "420721721202",
        email: "ladislav.dvorak@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/ladislav-dvorak.jpg",
      },
      {
        name: "Marek Houser",
        position: "Trustee",
        email: "marek.houser@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/marek-houser.jpg",
      },
      {
        name: "Pavel Vrbický",
        position: "Trustee",
        email: "pavel.vrbicky@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/pavel-vrbicky.jpg",
      },
    ],
  },
  {
    title: "Mediální tým",
    members: [
      {
        name: "Martin Saifrt",
        position: "Vedoucí krajského mediálního odboru",
        phone: "+42778439559",
        email: "martin.saifrt@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/martin.saifrt.jpg",
      },
    ],
  },
];

export const accommodation = [
  {
    title: "Hotel Atrium",
    link: "http://www.hotelatrium.cz",
    email: "info@hotelatrium.cz",
    desc: "Klidné a komfortní ubytování v přátelském a vytříbeném stylu. Hotel se nachází v klidné části centra Pardubic. Pokoje jsou moderně vybaveny, LCD televize, sprchou a toaletou. Snídaně jsou zahrnuty v ceně pokoje a parkování je zajištěné ve dvoře hotelu, který je uzamykatelný. Do Ideonu dojdete pěšky za 12 minut. K dispozici je 12 dvoulůžkových pokojů. V ceně je zahrnuta snídaně, parkování a připojení k internetu.",
    coords: { lat: 50.03466167295331, lon: 15.773254154152989 },
    distance: 800,
    priceClass: "$$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 1600,
      },
      {
        bedCount: 2,
        price: 1900,
      },
    ],
  },
  {
    title: "Hotel Euro",
    link: "https://www.hoteleuro.cz",
    email: "info@hoteleuro.cz",
    desc: "Prvotřídní 4 hvězdičkový hotel v blízkosti historického centra Pardubic. Pokoje jsou vybavené LCD televizí, sprchou a toaletou, součástí pokoje je přístup k wifi. Snídaně jsou zahrnuté v ceně pokoje a parkovat můžete přímo u hotelu. Do Ideonu dojdete pěšky za 6 minut. K dispozici je 50 dvoulůžkových pokojů.",
    coords: { lat: 50.032624029755524, lon: 15.780811840658778 },
    distance: 250,
    priceClass: "$$$",
    accessibility: {
      status: true,
      help: "Hotel Euro nabízí jeden bezbariérový pokoj, jinak jsou ve všech pokojích vany. Přístup do hotelu i do restaurace je kompletně bezbariérový a nachází se zde 2 výtahy.",
    },
    variants: [
      {
        bedCount: 1,
        price: 1500,
      },
      {
        bedCount: 2,
        price: 1900,
      },
    ],
  },
  {
    title: "Hotel Zlatá Štika",
    link: "http://www.zlatastika.cz",
    email: "recepce@zlatastika.cz",
    desc: "Klidný a standardně vybavený hotel, který se nachází 5 minut od centra. Hotelové pokoje jsou vybavené LCD televizí, sprchou a toaletou, na pokoji je přístup k WIFI. Snídaně jsou součástí ceny pokoje a parkování je zajištěno přímo u hotelu. Pěšky se z hotelu do Ideonu dostanete  za 10 minut. K dispozici je 46 pokojů, převážně dvoulůžkových.",
    coords: { lat: 50.039564111827566, lon: 15.783966227164703 },
    distance: 700,
    priceClass: "$$",
    accessibility: {
      status: true,
      help: "Hotel Zlatá Štika má bezbariérový přístup, a tím je zadní vchod, kde je zvonek. Jeden kompletně bezbariérový pokoj. Ostatní pokoje bezbariérové nejsou, nicméně se tam dá dostat výtahem, bez nutnosti schodů.",
    },
    variants: [
      {
        bedCount: 1,
        price: 1100,
      },
      {
        bedCount: 2,
        price: 1400,
      },
      {
        bedCount: 3,
        price: 1800,
      },
      {
        bedCount: 4,
        price: 2100,
      },
      {
        bedCount: 5,
        price: 2400,
      },
    ],
  },
  {
    title: "Hotel Labe",
    link: "http://www.hotellabe.cz",
    email: "rezervace@hotellabe.cz",
    desc: "Největší a nejznámější hotel v Pardubicích situovaný v samotném středu města. Vybavení odpovídá tříhvězdičkovému standardu. Pokoje jsou vybavené LCD televizí, toaletou a sprchou. Snídaně jsou zahrnuty v ceně pokoje. Hotel nemá parkovací místa, ale není daleko od vlakového nádraží. Do Ideonu se z hotelu dostanete do 30 minut.",
    coords: { lat: 50.037675717487396, lon: 15.767554754153124 },
    distance: 1500,
    priceClass: "$$",
    accessibility: {
      status: true,
      help: "Hotel Labe má bezbariérový přístup,  lze se z haly výtahem dopravit na pokoje. Bezbariérové pokoje s madlama přímo nemají, ale běžně se lidé s nutností bezbarierového přístupu zde ubytovávají. Nyní je omezen přístup výtahem do restaurace.. Pokoje jsou skoro stejné, ty původní mají vany, ale je zde i 18 rekonstruovaných pokojů, kde jsou sprchy.",
    },
    variants: [
      {
        bedCount: 1,
        price: 1000,
      },
      {
        bedCount: 2,
        price: 1200,
      },
    ],
  },
  {
    title: "Hotel Arnošt Pardubice",
    link: "http://hotel-arnost.cz",
    email: "hotel@hotel-arnost.cz",
    desc: "Tříhvězdičkový hotel s moderně zařízenými a prostornými pokoji, ve kterých se nachází i kuchyňská linka. Pokoje jsou vybaveny LCD televizí, WIFI připojení a sprchový kout s koupelnou. Snídaně je v ceně pokoje formou švédských stolů. U hotelu můžete zaparkovat své auto. Do Ideonu se dostanete pěšky za 12 minut. K dispozice je 76 lůžek.",
    coords: { lat: 50.03237624692707, lon: 15.776134011823473 },
    distance: 550,
    priceClass: "$$",
    accessibility: {
      status: true,
      help: "Hotel Arnošt nabízí také 1 pokoj upravený pro hosty na vozíčku. Do hotelu je bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 900,
      },
      {
        bedCount: 2,
        price: 1240,
      },
      {
        bedCount: 3,
        price: 1800,
      },
      {
        bedCount: 4,
        price: 2100,
      },
    ],
  },
  {
    title: "Hotel Kristl",
    link: "http://www.hotelkristl.cz",
    email: "hotelkristl@seznam.cz",
    desc: "Levné, ale kvalitní ubytování kousek od centra Pardubic. Pokoje jsou standardně vybaveny WIFI, sprchou, WC. Snídani si můžete za 80,- přikoupit. Na místě můžete zaparkovat své vozidlo. Od Ideonu je hotel vzdálen 9 minut. Pokoje jsou apartmánového typu, tedy dva pokoje, které mají společné sociální zařízení. Každý pokoj má dvě lůžka cena za obsazený pokoj dvěma osobami je 300,- Kč na osobu. Pokud někdo bude sám na dvoulůžák, pak cena je 450,- Kč. Pokud bude sám v celém apartmánu je to 650,- Kč. Doporučujeme, aby byla využita kapacita hotelu max. 40 lůžek se domluvit na spolunocležnících. Pokud bude pouze jedna osoba v apartmánu, tak ubytujeme minimum, což je 9 lidí. (4pokoje mají přistýlku)",
    coords: { lat: 50.0353806704769, lon: 15.788852027164491 },
    distance: 650,
    priceClass: "$",
    accessibility: {
      status: false,
      help: "Hotel Kristl bohužel nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 450,
      },
      {
        bedCount: 2,
        price: 600,
      },
    ],
  },
];

export const programGeneralSettings = {
  published: true,
};

export const program = {
  negotiation: {
    isUnderway: false,
    forumLink: "https://forum.pirati.cz/viewtopic.php?f=477&t=58315",
  },
  published: true,
  days: [
    {
      title: "sobota 8. 1. 2022",
      hourStart: 10,
      hourEnd: 19,
      // The smallest grid resolution in minutes. Take this into consideration when specifying time values.
      resolution: 5,
      tracks: [
        {
          code: "main",
          name: "Hlavní program",
          venue: "CF Online",
          colorBg: "blue-300",
          colorFg: "white",
        },
        {
          code: "voting",
          name: "Hlasování",
          venue: "Helios",
          colorBg: "black",
          colorFg: "white",
        },
      ],
      slots: [
        {
          time: "10:00",
          sessions: [
            {
              track: "main",
              timeStart: "10:00",
              timeEnd: "10:20",
              title: "Zahájení zasedání",
              agenda: [
                "Zahájení (20 minut)",
                "Úvodní slovo svolavatele",
                "Úvodní slovo organizátora",
                "Jmenování činovníků jednání",
              ],
            },
            {
              track: "main",
              timeStart: "10:20",
              timeEnd: "11:20",
              title: "Volba předsedy strany",
              link: "https://forum.pirati.cz/viewtopic.php?f=477&t=59138",
              agenda: [
                "Úvodní slovo předsedajícího (max 5 minut)",
                "Kandidátské řeči (5 minut každý)",
                "Diskuze s kandidáty",
              ],
            },
          ],
        },
        {
          time: "11:00",
          sessions: [
            {
              track: "main",
              timeStart: "11:20",
              timeEnd: "13:50",
              title: "Volba místopředsedů",
              link: "https://forum.pirati.cz/viewtopic.php?f=477&t=59138",
              agenda: [
                "Úvodní slovo předsedajícího (max 5 minut)",
                "Kandidátské řeči (5 minut každý)",
                "Diskuze s kandidáty",
              ],
            },
            {
              track: "voting",
              timeStart: "11:20",
              timeEnd: "12:20",
              title: "Volba předsedy, 1. kolo",
            },
          ],
        },
        {
          time: "12:00",
          sessions: [
            {
              track: "voting",
              timeStart: "12:20",
              timeEnd: "12:25",
              title: "Vyhlášení výsledku 1. kola volby předsedy",
            },
            {
              track: "voting",
              timeStart: "12:30",
              timeEnd: "13:30",
              title: "Volba předsedy, případné 2. kolo",
            },
          ],
        },
        {
          time: "13:00",
          sessions: [
            {
              track: "voting",
              timeStart: "13:30",
              timeEnd: "13:35",
              title: "Vyhlášení výsledku 2. kola volby předsedy",
            },
          ],
        },
        {
          time: "14:00",
          sessions: [
            {
              track: "voting",
              timeStart: "14:10",
              timeEnd: "15:10",
              title: "Volba místopředsedů, 1. kolo",
            },
            {
              track: "main",
              timeStart: "14:10",
              timeEnd: "15:10",
              title: "Oběd",
            },
          ],
        },
        {
          time: "15:00",
          sessions: [
            {
              track: "voting",
              timeStart: "15:10",
              timeEnd: "15:15",
              title: "Vyhlášení výsledku 1. kola volby místopředsedů",
            },
            {
              track: "main",
              timeStart: "15:20",
              timeEnd: "15:50",
              title: "Představení Pirátských ministrů a jejich plánů",
              agenda: [
                "Úvodní slovo předsedajícího (max 5 minut)",
                "Prezentace jednotlivých ministrů (5 minut každý)",
                "Diskuze s kandidáty",
              ],
            },
            {
              track: "voting",
              timeStart: "15:20",
              timeEnd: "16:20",
              title: "Volba místopředsedů, 2. kolo",
            },
            {
              track: "main",
              timeStart: "15:50",
              timeEnd: "16:10",
              title:
                "Zpětná vazba z kampaně, zpráva z pracovní skupiny republikového výboru",
            },
          ],
        },
        {
          time: "16:00",
          sessions: [
            {
              track: "voting",
              timeStart: "16:20",
              timeEnd: "16:25",
              title: "Vyhlášení výsledku 2. kola volby místopředsedů",
            },
            {
              track: "voting",
              timeStart: "16:40",
              timeEnd: "17:40",
              title: "Změna Stanov - hlasování - 1. kolo",
            },
            {
              track: "main",
              timeStart: "16:10",
              timeEnd: "16:40",
              title: "Změna Stanov - Zvýšení počtu členů RP na sedm",
              link: "https://forum.pirati.cz/viewtopic.php?f=477&t=58807",
              agenda: [
                "Úvodní slovo předsedajícího (max 5 minut)",
                "Slovo předkladatele",
              ],
            },
            {
              track: "main",
              timeStart: "16:50",
              timeEnd: "17:50",
              title: "Představení výroční zprávy předsedy strany",
            },
          ],
        },
        {
          time: "17:00",
          sessions: [
            {
              track: "voting",
              timeStart: "17:50",
              timeEnd: "17:55",
              title: "Vyhlášení výsledeku 1. kola změna Stanov",
            },
            {
              track: "voting",
              timeStart: "17:55",
              timeEnd: "18:55",
              title: "Změna Stanov - hlasování - 2. kolo",
            },
            {
              track: "main",
              timeStart: "17:55",
              timeEnd: "18:55",
              title:
                "Představení pirátských kandidátek a kandidátů na primátorky a primátory",
            },
          ],
        },
        {
          time: "18:00",
          sessions: [
            {
              track: "voting",
              timeStart: "18:55",
              timeEnd: "19:00",
              title: "Vyhlášení výsledeku 2. kola změna Stanov",
            },
          ],
        },
        {
          time: "19:00",
          sessions: [
            {
              track: "main",
              timeStart: "19:00",
              timeEnd: "19:10",
              title: "Ukončení zasedání",
              agenda: [
                "Závěrečné slovo organizátora",
                "Závěřečné slovo předsedajícího",
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const partyBureauCandidates = [
  {
    postTitle: "Předsednický post",
    candidates: [
      {
        name: "Janka Michailidu",
        desc: "Rodačka z Petrovic u Karviné a postgraduální studentka VŠCHT, je dlouholetou členkou Pirátů, aktuálně je členkou republikového výboru a členkou správní rady nově založeného Pirátského institutu Pí. Zastávala pozici místopředsedkyně strany, krátkodobě v roce 2014 i pozici předsedkyně Pirátů. Podílela se na programových postojích Pirátů v oblasti psychotropních látek. Jejím cílem je, aby Piráti vyšli z nárazu do reality silnější než dřív a pokusili se nalézt syntézu mezi idealismem a pragmatismem.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786120#p786120",
        img: "janka-michailidu.jpg",
      },
      {
        name: "Ivan Bartoš",
        desc: "Doktor informační vědy z FFUK, informační architekt, jeden ze zakládajících členů Pirátské strany, hudebník, čtenář, hráč deskových her a dlouholetý předseda Pirátské strany Ivan Bartoš. Jako poslanec v minulém volebním období předsedal sněmovnímu výboru pro regionální rozvoj. Spolu s oblastí digitalizace se nyní bude agendě místního rozvoje věnovat coby ministr a místopředseda nastupující vlády. Piráti jsou podle něj liberální středovou stranou s kvalitním programem bránícím svobodu lidí, vzdělanost a soudržnost společnosti. Politika je prostředkem změny, nikoliv cílem.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786951#p786951",
        img: "ivan-bartos.jpg",
      },
      {
        name: "Lukáš Wagenknecht",
        desc: "Současný senátor za Prahu 8 a 18, ekonom, auditor a vysokoškolský lektor je aktivním bojovníkem proti korupci a finančním machinacím. V roce 2015 obdržel Cenu za odvahu od Nadačního fondu proti korupci za své dlouhodobé nekompromisní postoje v roli auditora, když ukázal na nesrovnalosti v Dopravním podniku v Praze nebo na Ministerstvu financí. Zastává postoj, že správnou cestou v politice je být blízko občanům, jít mezi lidi, bavit se s nimi a naslouchat jejich názorům. Uvnitř strany chce spojovat různé myšlenkové směry.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785845#p785845",
        img: "lukas-wagenknecht.jpg",
      },
      {
        name: "David Witosz ",
        desc: "Místostarosta MO Moravská Ostrava a Přívoz, radní města Ostravy a garant programového bodu doprava pro volby do poslanecké sněmovny 2017 a 2021. V rámci úkolů ve vedení městského obvodu jsou jeho prioritami obnova a revitalizace bytového a nebytového fondu, lepší kultivace veřejného prostoru z pohledu komunálního úklidu, řešení problematiky statické dopravy a nové kontrolní metody ve stavebních investicích. Budoucí předseda Pirátů by podle něj měl splnit dva základní politické axiomy: Strana Pirátů se nestane stranou jednoho muže a naučí se používat slovo NE.",
        link: "https://forum.pirati.cz/viewtopic.php?p=788403#p788403",
        img: "david-witosz.jpg",
      },
    ],
  },
  {
    postTitle: "Místopředsednický post",
    candidates: [
      {
        name: "Jiří Snížek",
        desc: "Vystudovaný elektroinženýr a filosof, zastupitel Středočeského kraje a náměstek hejtmanky pro regionální rozvoj a územní plánování pochází ze Záboří nad Labem a nyní žije v Kolíně. Mezi lety 2018 a 2020 působil jako předseda středočeských Pirátů. Z pozice místopředsedy chce zajistit víc zdrojů pro prezentaci místních sdružení a jejich častější prezentaci v centrálních komunikačních kanálech a také transparentnější fungování odborného zázemí strany.",
        link: "https://forum.pirati.cz/viewtopic.php?p=783216#p783216",
        img: "jiri-snizek.jpg",
      },
      {
        name: "Blanka Charvátová",
        desc: "Jednatelka republikového výboru, předsedkyně místního sdružení Piráti Praha 21 a zastupitelka tamtéž. Momentálně například pracuje jako vedoucí pražského dopravního týmu na přípravě programu pro pražské magistrátní volby v roce 2022. Jako členka republikového předsednictva by se ráda zasadila o zlepšení komunikace Pirátů dovnitř i navenek. Chce, aby Piráti začali být pro voliče čitelnou stranou a aby se naučili s voliči mluvit jasně a srozumitelně.",
        link: "https://forum.pirati.cz/viewtopic.php?p=783987#p783987",
        img: "blanka-charvatova.jpg",
      },
      {
        name: "Hana Hajnová",
        desc: "Již během studia sociologie a evropských studií působila jako projektová manažerka a pomáhala rozvíjet inovační ekosystém na jižní Moravě. Po návratu na Vysočinu se aktivně od roku 2014 angažuje u Pirátů, od kapitánky dračích lodí po první náměstkyni hejtmana pro regionální rozvoj. V republikovém předsednictvu se chce zaměřit na snížení administrativy, zlepšení vnitřní komunikace a rozvoj lidských zdrojů, protože lidé a čas, který jsou ochotni Pirátům věnovat, je to nejcennější, co máme.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785220#p785220",
        img: "hana-hajnova.jpg",
      },
      {
        name: "Sámir Shanaáh",
        desc: "Absolvent oboru podnikání a managementu a nynější student psychologie managementu v současnosti profesně působí jako obchodní manažer společnosti. Je koordinátorem fundraisingu Pirátů a v Berouně je předsedou místního sdružení. Mezi jeho priority v místopředsednické funkci patří udržení pirátských hodnot, zefektivnění vnitřních procesů, členský růst strany a restrukturalizace organizační struktury.",
        link: "https://forum.pirati.cz/viewtopic.php?f=350&t=59342",
        img: "samir-shanaah.jpg",
      },
      {
        name: "Marcel Kolaja",
        desc: "Produktový manažer, odborník na informační technologie a dlouhodobý člen Pirátů je od roku 2019 europoslancem a místopředsedou Evropského parlamentu. Ve svém politickém působení se zaměřuje na otevřené technologie, zachování svobody na Internetu, ochranu nezávislosti médií a transparentní a sjednocenou Evropu. Mezi zásady, které chce v předsednictvu Pirátů reprezentovat patří svoboda informací a ochrana občanských svobod a práv, zejména pak práva na soukromí. Sociálně-liberální politika Pirátů má myslet na budoucnost a nenechat lidi na pospas ani zvůli státu, ani zájmům nadnárodních korporací.",
        link: "https://forum.pirati.cz/viewtopic.php?f=350&t=59473",
        img: "marcel-kolaja.jpg",
      },
      {
        name: "Jana Koláříková",
        desc: "Dlouhodobá spolupracovnice a členka Pirátů působila jako koordinátorka dobrovolníků pro Jihočeský kraj a vedoucí Personálního odboru Pirátů. Může nabídnout také své zkušenosti s mezinárodní neziskovou pomocí v oblasti vzdělávání, zkušenosti s rozvojovými projekty nebo redaktorskou praxí. Na pozici místopředsedkyně chce klást důraz na kulturu komunikace a důvěry uvnitř strany, efektivní zapojení nových členů, rozvoj kompetencí potřebných pro výkon politických funkcí a podporu regionů z centra. Piráty vidí jako aktivní a sebevědomou stranu s vlastními politickými tématy.",
        link: "https://forum.pirati.cz/viewtopic.php?p=783766#p783766",
        img: "jana-kolarikova.jpg",
      },
      {
        name: "Vojtěch Lukavec",
        desc: "Student všeobecného lékařství působí jako člen Resortního týmu Zdravotnictví a místopředseda Mladého Pirátstva. Je členem zdravotního výboru Středočeského kraje a má profesní zkušenosti jako školitel primární prevence a zdravotní bratr na ARO. Zabývá se epidemiologickými tématy, preventivní medicínou a pracovními podmínkami zdravotnického personálu. Za klíčové úkoly nového vedení považuje zlepšení komunikace směrem k médiím, jednotné a koordinované vystupování a návrat k liberálně progresivní politické suverenitě strany.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786070#p786070",
        img: "vojtech-lukavec.jpg",
      },
      {
        name: "Filip Křistek",
        desc: "Člen meziresortního týmu Návykové chování a Krajského expertního týmu pro bezpečnost je zároveň studentem oboru Evropská diplomacie a Politologie. Ve své práci pro Piráty se dosud zabýval převážně marketingem v oblasti sociálních sítí, grafikou i celkovým chodem organizace. Jeho vize spočívá v návratu k původním hodnotám a kořenům Pirátů jako jsou legalizace, transparentní stát a společenská progrese. Uvnitř strany chce prosazovat zapojení mladší generace, lepší informovanost členstva a efektivnější spolupráci mezi krajem a centrální úrovní.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785576#p785576",
        img: "filip-kristek.jpg",
      },
      {
        name: "Martin Jiránek",
        desc: "Analytik a marketingový poradce firem byl během minulého volebního období poslancem a do roku 2019 též zastupitelem města Trutnov. V rámci poslaneckého klubu byl osobou zodpovědnou za oblast průmyslu a obchodu, telekomunikací a cestovního ruchu. Piráty chce vidět jako úspěšnou, pevně ukotvenou a pro voliče čitelnou středovou stranu. V republikovém předsednictvu chce proto řešit ujasnění si základních pirátských a politických hodnot, tedy jakou stranou v jakém státě chceme být, a k tomu přidat chytrou komunikaci ke členům i ven bez zbytečných střílení se do nohy.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786044#p786044",
        img: "martin-jiranek.jpg",
      },
      {
        name: "Petr Kapounek",
        desc: "Prostějovský zastupitel a předseda komise IT a Smart Region v Olomouckém kraji je povoláním projektový manažer v telekomunikační společnosti. Považuje se za libertariána a chce proto Česko směřovat k vzdělanostní společnosti a hodnotám svobody založeným na osobní odpovědnosti. Chce, aby Piráti přispívali k vytváření podmínek pro průhledný a efektivní stát bez šikany a ČR se tak mohla stát ekonomickým tygrem. Nechce, aby Piráti byli stranou vyššího dobra, která lidi převychovává pomocí omezení, nařízení a zákazů.",
        link: "https://forum.pirati.cz/viewtopic.php?f=350&t=59255",
        img: "kapounek-petr.jpg",
      },
      {
        name: "Antonino Milicia",
        desc: "Odborník se vzděláním a praxí v oblasti strategie, projektů a organizačního rozvoje. Piráti jsou pro něj jediná strana poctivé a autentické politiky, která se však musí poučit ze svých chyb. Své hlavní poslání vidí v nastavení strategického směřování a rozvoje strany, srozumitelné komunikaci programu a zlepšení vnitřní kultury a demokracie. Chce také usilovat o snížení byrokracie, vyšší akceschopnost vedení a účinnější zapojení členů. Jeho osobní vizí je pomoci z Pirátů vytvořit dobře fungující organismus, kde každý může být užitečný a s radostí pomáhat měnit svět k lepšímu.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786036#p786036",
        img: "antonino-milicia.jpg",
      },
      {
        name: "Lucie Cirkva Chocholová",
        desc: "Zastupitelka Středočeského kraje a předsedkyně Výboru pro památkovou péči, kulturu a cestovní ruch má přes dvacet let praxe v kultuře jak v soukromé, tak i veřejné sféře, včetně práce na Ministerstvu kultury. Piráty vnímá jako politickou sílu, která může navrátit důvěru lidí v politiku pomocí aplikace demokratických principů a zaměření na odbornost. Jejím cílem je reflektování současné liberální politiky a navázání na jádrová témata Pirátů. Chce co nejlépe využít lidský potenciál a zamezit vzniku osobních kultů.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785404#p785404",
        img: "lucie-chocholova.jpg",
      },
      {
        name: "Ondřej Profant",
        desc: "Dřívější poslanec a zastupitel hlavního města Prahy se po dobu svého politického působení zaměřuje na témata spojená s digitalizací a zavádění open source softwaru. Je aktivní i prostřednictvím spolku Otevřená města, zabývá se svobodnou kulturou a stál za pražským projektem otevřených dat. Na pozici místopředsedy chce přispět k vyřešení vnitrostranických sporů a budování značky Pirátů. Zároveň vidí jako jednu z výzev efektivní předávání vědomostí a schopností nových členů/členek a kandidátů/kandidátek.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785610#p785610",
        img: "ondrej-profant.jpg",
      },
      {
        name: "Jana Skopalíková",
        desc: "Místopředsedkyně krajského sdružení Středočeský kraj a současně radní pro životní prostředí a zemědělství Středočeského kraje. Vystudovaná botanička se zaměřuje na činnosti spojené s ochranou životního prostředí a sociální pomoc, jako jsou akce Ukliďme Česko nebo Pirátský guláš. V republikovém předsednictvu by chtěla přispět k větší mediální podpoře krajských a regionálních osobností, podpoře a školení s výhledem na komunální volby. Plánuje také lepší práci s nově přijatými členy.",
        link: "https://forum.pirati.cz/viewtopic.php?p=788454#p788454",
        img: "jana-skopalikova.jpg",
      },
      {
        name: "Dalibor Záhora",
        desc: "Zakládající člen Pirátů, který se již od devadesátých let věnuje nezávislým kulturním i politickým aktivitám, spolupracuje i s nevládními organizacemi bránícími práva spoluobčanů. Stál při vzniku Pirátských novin a podílel se na budování středočeské organizace Pirátů. Inicioval také kandidaturu senátorky Adély Šípové. Jako zásadní problém, který chce řešit, vidí centralizaci strany, jíž chce čelit návratem rozhodovacích pravomocí do stranické struktury. Chce zajistit, aby nebyla omezována práva řadových členů a členek a byla brána vedením strany na vědomí.",
        link: "https://forum.pirati.cz/viewtopic.php?p=787584#p787584",
        img: "dalibor-zahora.jpg",
      },
      {
        name: "Jiří Knotek",
        desc: "Absolvent Politologie a Evropských studií na Univerzitě Palackého v Olomouci se s Piráty účastnil několika předvolebních kampaní. Aktuálně je zlínským krajským zastupitelem a předsedou výboru pro kulturu a památky. Předmětem jeho zájmu je zahraniční a bezpečnostní politika ČR a mezinárodní vztahy. Jako místopředseda se chce aktivně zapojit do komunikace a reprezentace Pirátů navenek a pomáhat kandidátům do komunálních voleb s kampaní a konzultovat předvolební kroky.",
        link: "https://forum.pirati.cz/viewtopic.php?p=784663#p784663",
        img: "jiri-knotek.jpg",
      },
      {
        name: "Martin Kučera",
        desc: "Zastupitel městského obvodu Moravská Ostrava a Přívoz je dlouholetým Pirátem, dřívějším místopředsedou nebo vedoucím administrativniho odboru. Je vystudovaný matematický analytik, v legislativě ho zajímají především oblasti související s tvůrčí činností a jejími dopady na školství či zdravotnictví, věnuje se též problematice internetu či stavu životního prostředí. V předsednictvu chce hájit principy kontroly práce funkcionářů, ochranu soukromí občanů, včetně obhajoby svobody na internetu proti cenzuře. Chce dosáhnout revize kopírovacího monopolu v podmínkách 21. století a svobody pěstování a zpracování konopí.",
        link: "https://forum.pirati.cz/viewtopic.php?p=787040#p787040",
        img: "martin-kucera.jpg",
      },
      {
        name: "Šárka Václavíková",
        desc: "Předsedkyně Young Pirates of Europe působila jako koordinátorka kampaně pro komunální volby 2018 nebo jako volební manažerka Jiřího Kadeřávka. V Moravskoslezském kraji byla koordinátorkou pro krajské volby 2020, mimo to se věnovala občanskému aktivismu. V současnosti je asistentkou pražského radního Víta Šimrala. Jako místopředsedkyně bude klást důraz na rovné šance a práva, svobodu pro všechny bez rozdílu, legalizaci a transparenci. Též stojí o lepší mezinárodní propojení Pirátů.",
        link: "https://forum.pirati.cz/viewtopic.php?p=788634#p788634",
        img: "sarka-vaclavikova.jpg",
      },
    ],
  },
];
