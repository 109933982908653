import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

const Volunteers = () => {
  return (
    <article className="w-full">
      <PageTitle>Pro dobrovolníky</PageTitle>

      <Helmet>
        <title>Pro dobrovolníky | {basics.appTitle}</title>
        <meta property="og:title" content="Pro dobrovolníky" />
      </Helmet>

      <div className="content-block">
        <h3>Koho hledáme</h3>

        <ul>
          <li>
            cca 5 dobrovolníků na registrační místo (sobota 8:00 až do doby
            potřebné k akreditaci členů)
          </li>
          <li>
            cca 2-4 dobrovolníky k zajištění dalších potřebných věcí (příprava
            prostor, běžná agenda)
          </li>
        </ul>

        <h3>Kam se nahlásit</h3>
        <p>
          V případě jakýchkoliv dotazů ohledně tvé pomoci kontaktuj{" "}
          <a href="tel:+420778111450">Ivanu Mrkvičkovu</a>.
        </p>

        <h3>Musím být k dispozici po celou dobu?</h3>
        <p>
          Ne. Budeme rádi za každou pomoc. Největší nápor bude mezi 8:30 až
          10:00 v sobotu na registraci. Pak už to zvládneme v menším počtu.
          Během dne určitě zvládneš pokec s kamarády nebo známými, tak aby sis{" "}
          {basics.appTitle} taky mohl(a) užít.
        </p>

        <h3>Co za to získám?</h3>
        <p>
          Peníze nenabízíme. Získáš dobrý pocit, skvělý zážitek, spoustu nových
          kontaktů a pirátské body. Vhodné také pro zájemce, kteří jsou čekatelé
          na členství u Pirátů a nebo o tom teprve uvažují.
        </p>

        <h3>Adresa jednacího sálu</h3>
        <p>
          Výstavní a společenské centrum IDEON
          <br />
          Jiráskova 1963, Pardubice
        </p>
      </div>
    </article>
  );
};

export default Volunteers;
