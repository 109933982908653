import React from "react";
import { Helmet } from "react-helmet";
import { MapContainer, TileLayer } from "react-leaflet";

import MarkerItem from "components/MarkerItem";
import PageTitle from "components/PageTitle";
import { basics, map } from "config";

const Transportation = () => {
  const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  return (
    <article className="space-y-8 w-full">
      <PageTitle>Doprava</PageTitle>
      <Helmet>
        <title>Doprava | {basics.appTitle}</title>
        <meta property="og:title" content="Mapa" />
      </Helmet>

      <div className="content-block">
        <h2>Kde jednání probíhá?</h2>
      </div>

      <div className="grid grid-cols-4 gap-4">
        <MapContainer
          center={[map.center.lat, map.center.lon]}
          zoom={14}
          scrollWheelZoom={true}
          style={{ height: "25rem", width: "100%" }}
          className="col-span-4"
        >
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/xaralis/ck4oblwty0fgk1fjzxmqow2r5/tiles/256/{z}/{x}/{y}@2x?access_token=${accessToken}`}
            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
          />
          {map.markers.map((marker) => (
            <MarkerItem
              key={marker.title}
              type={marker.type}
              coords={marker.coords}
              desc={
                <>
                  <strong>{marker.title}</strong>
                  <div>{marker.desc}</div>
                </>
              }
            />
          ))}
        </MapContainer>
        <div className="content-block col-span-4">
          <p>
            <a
              href="https://www.pvv.cz/index.php"
              target="_blank"
              rel="noreferrer noopener"
            >
              Výstavní a společenské centrum IDEON
            </a>
            <br />
            Jiráskova 1963
            <br />
            530 02 Pardubice
            <br />
            GPS: 50.0347, 15.7817
          </p>
        </div>
      </div>

      <div className="content-block">
        <h2>Jak se tam dostanu?</h2>

        <h3>Vlakem</h3>

        <p>
          Pardubice leží na hlavním železničním koridoru a díky tomu můžete
          vyrazit do pořadatelského města po kolejích. Takže pokud jste z Prahy,
          Ostravy, nebo z Jablunkova a cestování je pro vás zážitkem, tak určitě
          využijte vlak. Je to nejjednodušší.
        </p>

        <p>
          Z nádraží se na místo konání dostanete buď cca půlhodinovou procházkou
          — půjdete od nádražní budovy doprava a víceméně stále rovně, až
          narazíte na Zelenou bránu a Pernštýnské náměstí. Poté co ho přejdete a
          pokocháte se třeba krásnou freskou znázorňující biblického Jonáše, jsa
          pojídán velrybou, dáte se doprava kolem krajského úřadu a přes silnici
          pokračujete stále rovně. Po pár metrech pak již uvidíte místo jednání.
          Pro ty z Vás, kterým se nechce šlapat pěšky, můžeme doporučit sdílené
          koloběžky Bolt či kola Nextbike.
        </p>

        <p>
          Na místo se též lze dostat veřejnou dopravou. Přímo před budovou
          hlavního nádraží je terminál MHD, autobusy a trolejbusy do centra
          odjíždějí ze stanovišť 1 a 2. K přiblížení použijte autobusy 6 nebo 8
          (zastávka Karla IV., následně podchodem pod silnicí, mírně doprava a
          jste na místě), případně autobusy 9 a trolejbusy 2, 12, 13 (zastávka
          Krajský úřad, následně od zastávky proti proudu řeky, u dalšího mostu
          mírně doprava, pak budete na místě). Jízdné můžete v MHD zaplatit
          platební kartou, je to nejlevnější způsob. Je dobré si jízdenku
          označit i při výstupu, může Vám to zlevnit jízdu. Pokud byste však
          chtěli koupit jízdenku, stačí první pásmo. Pozor, jízdenky v
          Pardubicích nejsou přestupní!
        </p>

        <h3>Autem</h3>
        <p>
          Pokud zvolíte dopravu po vlastní ose, tak se k nám přiblížila i nějaká
          ta dálnice, nejblíže je D11 z Prahy, dojedete až do Opatovic, pak
          pohodlně dvouproudovce až do Pardubic. Z druhého konce se pilně buduje
          D35 a z jihu k nám sice také nevede dálnice, ale dorazíte též bez
          větších zádrhelů. GPS vám cestu najde a autem se do hlavního města
          Pardubického kraje bez problémů dostanete.
        </p>
        <h4>Parkování</h4>

        <p>
          Parkovat lze buď přímo před IDEONem, nebo můžete využít{" "}
          <a
            href="https://goo.gl/maps/xVgbnEeXvZ2kUuyj7"
            target="_blank"
            rel="noreferrer noopener"
          >
            parkovací dům Karlovina
          </a>
          .
        </p>
      </div>
    </article>
  );
};

export default Transportation;
