import React from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";

const icon = (type, size) => {
  const markerIcons = {
    event: "ico--pirati",
    accommodation: "ico--bed",
    train: "ico--train",
    location: "ico--location",
  };

  return L.divIcon({
    html: `<i class="${markerIcons[type]}" style="font-size: ${size}px"></i>`,
    iconSize: [size, size],
    className: "",
  });
};

const iconMap = {
  accommodation: icon("accommodation", 24),
  event: icon("event", 48),
  train: icon("train", 24),
  location: icon("location", 24),
};

const MarkerItem = ({ type, coords, title, desc }) => {
  return (
    <Marker
      position={[coords.lat, coords.lon]}
      key={title}
      icon={iconMap[type]}
    >
      <Popup>{desc}</Popup>
    </Marker>
  );
};

export default MarkerItem;
