import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

const Party = () => {
  return (
    <article className="w-full">
      <PageTitle>Virtual music party</PageTitle>

      <Helmet>
        <title>Virtual music party | {basics.appTitle}</title>
        <meta property="og:title" content="Virtual music party" />
      </Helmet>

      <div className="content-block">
        <p>
          Pirate DJs si pro posluchače a diváky opět připravili celkem tři
          streamovací kanály s tematickou žánrovou náplní. A to jak na sobotní
          večer po zasedání, tak i na páteční zahřívačku. Pusťte pirátské DJs do
          svých obýváků, sluchátek nebo sklepení! Sousedi se letos ještě vyspí
          dost! Přejeme příjemnou zábavu
        </p>

        <p>Přesný časový program ještě doplníme.</p>

        <p>
          <a href="https://www.facebook.com/events/3018946831656543/">
            Událost na facebooku
          </a>
        </p>
      </div>
    </article>
  );
};

export default Party;
