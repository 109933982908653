import { NavLink } from "react-router-dom";
import classNames from "classnames";

import { nav } from "config";

const VNav = ({ className, ...props }) => {
  const cls = classNames(
    className,
    "vertical-navbar container-padding--zero md:container-padding--auto"
  );

  return (
    <nav className={cls} {...props}>
      <ul className="vertical-navbar__items">
        <li>
          <NavLink to="/">Rozcestník</NavLink>
        </li>
        {nav
          .filter((navItem) => !!navItem.link)
          .map((navItem) => (
            <li key={navItem.link}>
              <NavLink
                to={navItem.link}
                className="vertical-navbar__item"
                activeClassName="vertical-navbar__item--active px-4 md:px-8 -mx-4 md:-mx-8"
              >
                {navItem.title}
              </NavLink>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default VNav;
