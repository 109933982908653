import React from "react";
import classNames from "classnames";

const Card = ({ children, className }, ref) => {
  const cls = classNames("card", className);
  return (
    <div className={cls} ref={ref}>
      {children}
    </div>
  );
};

export default React.forwardRef(Card);
