import Button from "components/Button";
import CrossroadCard from "components/CrossroadCard";
import { nav } from "config";

import cfLogo from "../gingerbread.svg";

import cfPattern from "./gingerbread-pattern-colourful-384px.svg";

const Home = () => {
  const delta = Math.abs(new Date(2022, 0, 8, 10, 0, 0) - new Date()) / 1_000;
  const days = Math.floor(delta / 86_400);
  const hours = Math.floor((delta - days * 86_400) / 3_600) % 24;

  return (
    <article className="space-y-8 md:space-y-16">
      <div className="bg-black text-white p-8 md:p-16">
        <div className="content-block mb-8">
          <h1 className="head-alt-md lg:head-alt-lg">Fórum proběhne online</h1>
          <p className="text-md lg:text-lg">
            Ačkoliv jsme byli připraveni udělat maximum možného, abychom se
            letos mohli vidět osobně, aktuální epidemické podmínky nejsou
            příznivé. Proto byla nakonec jako v minulém roce zvolena online
            forma zasedání.
          </p>
          <p className="text-md lg:text-lg">
            Zasedání proběhne na adrese{" "}
            <a href="https://cf2022.online" className="font-bold">
              cf2022.online
            </a>
            .
          </p>
        </div>
        <Button
          className="md:text-xl btn--to-blue-300"
          bodyClassName="whitespace-no-wrap"
          color="white"
          href="https://cf2022.online"
          icon="ico--chevron-right"
          rel="noreferrer noopener"
          target="_blank"
        >
          Sledovat CF 2022 online
        </Button>
      </div>
      <div className="relative card elevation-10 flex flex-col lg:flex-row lg:items-center my-8 xl:-mx-16 container-padding--zero md:container-padding--auto overflow-hidden">
        <div
          className="cf-hero"
          style={{ background: `url(${cfPattern})` }}
        ></div>
        <div className="cf-hero-claim w-full flex items-center justify-center text-center lg:text-left p-8 py-16 md:p-16">
          <img
            src={cfLogo}
            alt="CF 2022"
            className="hidden lg:block w-48 mr-16"
          />
          <div>
            <h1 className="head-alt-lg md:head-alt-xl mb-4">
              Začínáme za
              <br />
              {days} dní a {hours} hodin!
            </h1>
            <Button
              className="md:text-xl btn--to-blue-300"
              bodyClassName="whitespace-no-wrap"
              color="black"
              routerTo="/program"
              icon="ico--chevron-right"
            >
              Program
            </Button>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-3 gap-4 md:gap-8">
        {nav.map((navItem) => (
          <CrossroadCard
            key={navItem.title + "__" + navItem.link}
            title={navItem.title}
            accent={navItem.accent}
            link={navItem.link}
            underConstruction={!navItem.link}
          />
        ))}
      </div>
    </article>
  );
};

export default Home;
